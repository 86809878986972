/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading } from 'theme-ui';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';
import ProductDescriptionFeatures from '../../plugins/storefront/src/components/ProductDescriptionFeatures';

const MonotapesPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `MonotapesPage.Title`,
          message: `Why Monotapes`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `MonotapesPage.MetaDescription`,
            message: `Innovative technology. You can install monotapes in just 20 minutes at home. Wash, style and cut just like your own hair.`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `MonotapesPage.BreadcrumbsTitle`,
              message: `Why Monotapes`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 0]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 950,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }} mb={[2, 3]}>
          <Trans id="MonotapesPage.Heading">Why Monotapes</Trans>
        </Heading>
        <ProductDescriptionFeatures />
      </Box>
    </>
  );
};

export const MonotapesPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default MonotapesPage;
